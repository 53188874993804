import Tooltip from "bootstrap/js/src/tooltip"
import Modal from "bootstrap/js/src/modal"
import Collapse from "bootstrap/js/src/collapse"
import animateScrollTo from "animated-scroll-to"

import kvalitetFull from "url:../images/video/kvalitet-hoy.mp4"
import kvalitetMedium from "url:../images/video/kvalitet-medium.mp4"
import kvalitetLav from "url:../images/video/kvalitet-lav.mp4"

/**
 * @type {Modal}
 */
var popupPersonvern

var tooltips = []

var clone = o => Object.assign(Object.create(Object.getPrototypeOf(o)), o)

/**
 * Runs the given string through dom-purify and trims the result.
 * @param {string} dt Dirty string.
 * @returns Sanitized string.
 */
var s = dt => String(DOMPurify.sanitize(String(dt))).trim()

/**
 * Same as jquery $ (wrapper for getElementById), probably simplified - I've never looked at their code.
 * @param {string} id Element ID.
 * @returns {HTMLElement} Element.
 */
var $ = id => document.getElementById(id)

/**
 * Wrapper for document.querySelector.
 * @param {string} s CSS selector string.
 * @returns {HTMLElement} Element.
 */
var q = s => document.querySelector(s)

/**
 * Wrapper for querySelectorAll.
 * @param {string} s CSS selector string.
 * @returns {NodeList} Node list.
 */
var _ = s => document.querySelectorAll(s)

/**
 * Convenience function for sanitizing user input.
 * @param {string} id HTMLInputElement ID.
 * @returns {string} Returns the .value of the given element, sanitized by dom-purify, and trimmed.
 */
var v = id => String(DOMPurify.sanitize(String($(id).value))).trim()

/**
 * Check whether the given elements .reportValidity returns true or false.
 * @param {string} id Element ID.
 * @returns {boolean} True or false.
 */
var checkValidity = id => $(id).reportValidity() === true

// /**
//  * @param {Error} err Error som kastes.
//  */
// function fetchFeil(err) {
// 	$("feilmelding").innerHTML = err.message + "<br><br><span class='text-success'>Noen deler av nettsiden kan enda fungere selv om denne feilmeldingen viser.</span>"
// 	$("feilmelding").classList.remove("d-none")
// }

// Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.3809.100 Mobile Safari/537.36 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)
// Mozilla/5.0 AppleWebKit/537.36 (KHTML, like Gecko; compatible; Googlebot/2.1; +http://www.google.com/bot.html) Chrome/92.0.3809.100 Safari/537.36
// Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)

async function doInit() {
	// var browserSupport = /((CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS)[ +]+(11|(1[2-9]|[2-9]\d|\d{3,})|12|(1[3-9]|[2-9]\d|\d{3,})|13|(1[4-9]|[2-9]\d|\d{3,})|14|(1[5-9]|[2-9]\d|\d{3,})|15|(1[6-9]|[2-9]\d|\d{3,}))[_.]\d+(?:[_.]\d+)?)|(CFNetwork\/8.* Darwin\/17\.0\.\d+)|(CFNetwork\/8.* Darwin\/17\.2\.\d+)|(CFNetwork\/8.* Darwin\/17\.3\.\d+)|(CFNetwork\/8.* Darwin\/17\.\d+)|(Opera\/.+Opera Mobi.+Version\/(64|(6[5-9]|[7-9]\d|\d{3,}))\.\d+)|(Opera\/(64|(6[5-9]|[7-9]\d|\d{3,}))\.\d+.+Opera Mobi)|(Opera Mobi.+Opera(?:\/|\s+)(64|(6[5-9]|[7-9]\d|\d{3,}))\.\d+)|((?:Chrome).*OPR\/(71|(7[2-9]|[8-9]\d|\d{3,}))\.\d+\.\d+)|(SamsungBrowser\/(8|(9|\d{2,})|10|(1[1-9]|[2-9]\d|\d{3,})|11|(1[2-9]|[2-9]\d|\d{3,})|12|(1[3-9]|[2-9]\d|\d{3,}))\.\d+)|(Edge\/(85|(8[6-9]|9\d|\d{3,}))(?:\.\d+)?)|((Chromium|Chrome)\/(85|(8[6-9]|9\d|\d{3,}))\.\d+(?:\.\d+)?)|(Version\/(11|(1[2-9]|[2-9]\d|\d{3,})|12|(1[3-9]|[2-9]\d|\d{3,})|13|(1[4-9]|[2-9]\d|\d{3,})|14|(1[5-9]|[2-9]\d|\d{3,})|15|(1[6-9]|[2-9]\d|\d{3,}))\.\d+(?:\.\d+)? Safari\/)|(Firefox\/(84|(8[5-9]|9\d|\d{3,}))\.\d+\.\d+)|(Firefox\/(84|(8[5-9]|9\d|\d{3,}))\.\d+(pre|[ab]\d+[a-z]*)?)/

	// if (
	// 	!isbot(navigator.userAgent) &&
	// 	!browserSupport.test(navigator.userAgent)
	// ) {
	// 	throw new Error(
	// 		"Nettleseren din er ikke støttet. Last ned ny versjon av Firefox på <a href='https://getfirefox.com'>getfirefox.com</a> eller hvis du er på telefon/nettbrett så gjør det via din programbutikk.<br>" +
	// 		"Eller du kan besøke oss på <a href='https://business.facebook.com/jitsulab.no/'>Facebook</a> eller <a href='https://www.instagram.com/jitsu_lab/'>Instagram</a>!<br>" +
	// 		"jitsulab.no skal også fungere med Chrome og Microsoft Edge, men de anbefaler vi ikke.<br>" +
	// 		"Datatilsynet skriver om Google for <a href='https://www.datatilsynet.no/personvern-pa-ulike-omrader/skole-barn-unge/bruk-av-google-chromebook-og-g-suite-for-education-og-andre-skytjenester-i-grunnskolen/'>eksempel her</a> <a href='https://www.datatilsynet.no/aktuelt/aktuelle-nyheter-2020/varsel-om-irettesettelse-for-feil-bruk-av-googles-losninger-i-skolen/'>eller her.</a> Microsoft Edge er rett og slett en direkte kopi av Chrome."
	// 	)
	// }

	//feilContainer = new Toast($("feil-toast"))

	var ttopts = {
		trigger: "hover"
	}
	_("[data-bs-toggle='tooltip']").forEach(el => {
		tooltips.push(new Tooltip(el, ttopts))
	})

	popupPersonvern = new Modal($("popup_personvern"), {
		backdrop: "static",
		keyboard: false,
		focus: true
	})

	// var menu = new Collapse($("meny"), {
	// 	toggle: false,
	// })

	// var hideMenu = function(ev) {
	// 	menu.hide()
	// }

	// var moveAndHide = function(ev) {
	// 	if ( ev.target && ev.target.href && ev.target.hash && ev.target.classList.contains("nav-link") ) {
	// 		console.log(ev.target.hash.substring(1))
	// 		$(ev.target.hash.substring(1)).scrollIntoView()
	// 	}
	// 	hideMenu()
	// }

	var hideMenu = function (ev) {
		Collapse.getOrCreateInstance($("meny"), { toggle: false }).hide()
		let to = ev.target.getAttribute("data-v-to")
		animateScrollTo($(to), {
			verticalOffset: -90,
			maxDuration: 1200,
			minDuration: 0
		})
	}

	$("btn-treningstider").onclick = hideMenu
	$("btn-hvor").onclick = hideMenu

	_(".nav-link").forEach(el => {
		el.addEventListener("click", hideMenu, false)
		//el.addEventListener("tap", hideMenu, false)
		//el.addEventListener("touchstart", hideMenu, false)
	})

	var video = $("bakgrunnsvideo")
	var w = document.documentElement.clientWidth
	if (w < 768) {
		video.setAttribute("src", kvalitetLav)
	} else if (w < 1200) {
		video.setAttribute("src", kvalitetMedium)
	} else {
		video.setAttribute("src", kvalitetFull)
	}
}

function init() {
	doInit()
}

if (document.readyState === "loading") {
	document.addEventListener("DOMContentLoaded", init)
} else {
	init()
}
